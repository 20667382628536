// src/components/Contract.js
import React from 'react';
import ContractCalendar from './ContractCalendar';

const Contract = () => {
  return (
    <div>
      Contract Page
      <ContractCalendar></ContractCalendar>
    </div>
  );
};

export default Contract;
